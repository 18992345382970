
import React, { Component } from "react";




export default class Blog extends Component {
  render() {
    return (

      <div className="container blog-container mt-4">
        <div className="row main-row ">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="blog-img text-center">
              <img className="img-fluid" src={this.props.img} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="blog-title mt-lg-5 mt-md-2 mt-sm-1">
            <h2 className="text-center p-2 animate__animated animate__pulse animate__delay-4s animate__infinite ">
                {this.props.title2}
                Mihai &nbsp;
                <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                </svg>
                                &nbsp;   Ilinca
              </h2>
            <h5 className="text-center" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                {this.props.title1}
                Cu mult drag și încântare <br/>
                Vă invităm la o petrecere tare!<br/>
                Cu muzică și voie bună<br/>
                Ne vom distra clar împreună!
              </h5>
              
              <h4 className="text-center pb-5">
                {this.props.message}
              </h4>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

