import restaurant from "./images/checco.jpg";
import img_card from "./images/n1.jpg";
import img_nasi from "./images/n9.jpg";
import img_bucatar from "./images/n1.jpg";
import img_music from "./images/n2.jpg";
import imgOmSuflet from "./images/pahare.webp";
import imgOmSufletmb from "./images/vin3.jpg";
import imgheader from "./images/fon4.jpg";
import imgheaderm from "./images/fon4.jpg";
import imgheadermiini from "./images/robloxh.jpg";
import imgheadermiinimb from "./images/robloxm.jpg";
import imgconfirmare from "./images/minioni.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        mire: "",
        mireasa: "",
        tata: "",
        mama: "",
        copilul: "Mihai & Ilinca",
        familia: "fam. Vintuleac",
        title: "Invitatie Online",
        data: "9 Iulie 2023",
        data_confirmare: "",
        savedata: "Vă invităm!",
        ani: "",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "vintuleac93@gmail.com", 
       tel: "+393890989789",
       phone: "tel:+393890989789",
       viber: "viber://chat?number=%2B393890989789",
       whatsapp: "https://wa.me/++393890989789",
       messenger: "https://www.messenger.com/t/",
       tel1: "+40740907712",
       phone1: "tel:+40740907712",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+40740907712",
       messenger1: "https://www.messenger.com/t/",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Checco dello Scapicollo",
            data: "9 iulie 2023, duminică, ora 19:30",
            adress: "Via dei Genieri, 5, 00143 Roma RM, Italy",
            harta: "https://goo.gl/maps/EXayVn3aSSLdeMq66",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2973.4963835587387!2d12.4836944!3d41.817608299999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13258b082b949df5%3A0xd579092b9eddf30d!2sChecco%20dello%20Scapicollo!5e0!3m2!1sro!2s!4v1687249053886!5m2!1sro!2s"  
           
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Invitație!",
            title2: "VĂ INVITĂM LA ZIUA NOASTRĂ!",
            message: "Pentru că împlinesc 12 ANI, te invit cu mare drag, să sărbătorim împreună, cu muzică, dans și multe suprize!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img_nasi,
            title1: "",
            title2: "",
            message: '',
        },
    ],
    blogDataBucatar: [
        {
            id: 2,
            img: img_bucatar,
            title1: "Vă invităm alături de cea mai bună bucătăreasă:",
            title2: "Natalia Căpătici Vizitiu",
            message: "Și să gustăm din bucatele delicioase a mânuților de aur!",
        }
    ],
    blogDataMusic: [
        {
            id: 2,
            img: img_music,
            title1: "Sub conducerea muzicală de:",
            title2: "Deejay Vibe",
            message: "Distracția și dansurile sunt asigurate până dimineață!",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            img1: imgOmSufletmb,
            title: "Ne vom distra pe bune!",
            message: "Alături de cei dragi!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "12 ANI",
            message: "LA MULȚI ANI!",
        }
    ],
    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ], 

}

export default data;