import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "../css/Styles.css";

// import required modules
import {Autoplay, EffectCoverflow, Pagination } from "swiper";


import img1 from '../images/n1.jpg';
import img2 from '../images/n2.jpg';
import img3 from '../images/n3.jpg';
import img4 from '../images/n4.jpg';
import img5 from '../images/n5.jpg';
import img6 from '../images/n6.jpg';
import img7 from '../images/n7.jpg';
import img8 from '../images/n8.jpg';
import img9 from '../images/n9.jpg';
import img10 from '../images/n10.jpg';
import img11 from '../images/n11.jpg';
import img12 from '../images/n12.jpg';
import img13 from '../images/n21.jpg';
import img14 from '../images/n14.jpg';
import img15 from '../images/n15.jpg';
import img16 from '../images/n16.jpg';
import img17 from '../images/n17.jpg';
import img18 from '../images/n18.jpg';

const Slide = () => {
    let data = [
        {
            id: 1,
            imgSrc: img1,
        },
       
        {
            id: 2,
            imgSrc: img2,
        },
        {
            id: 3,
            imgSrc: img3,
        },
        {
            id: 4,
            imgSrc: img4,
        },

        {
            id: 5,
            imgSrc: img5,
        },
        {
            id: 6,
            imgSrc: img6,
        },
        {
            id: 7,
            imgSrc: img7,
        },
        {
            id: 8,
            imgSrc: img8,
        },

        {
            id: 9,
            imgSrc: img9,
        },
        {
            id: 10,
            imgSrc: img10,
        },
       
        {
            id: 11,
            imgSrc: img11,
        },
        {
            id: 12,
            imgSrc: img12,
        },
        {
            id: 13,
            imgSrc: img13,
        },

        {
            id: 14,
            imgSrc: img14,
        },
        {
            id: 15,
            imgSrc: img15,
        },
        {
            id: 16,
            imgSrc: img16,
        },
        {
            id: 17,
            imgSrc: img17,
        },

        {
            id: 18,
            imgSrc: img18,
        },
    ]
    return (
        <>

     <div className="slider mb-4 pb-4">
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                pagination={true}
                modules={[Autoplay,EffectCoverflow, Pagination]}
                className="mySwiper"
            >
                {data.map((item, index) => {
                    return (
                           
<SwiperSlide>
                            <img src={item.imgSrc} />
                        </SwiperSlide>
        
                        
                    )
                })}


            </Swiper></div>
        </>
    );
}

export default Slide;